<template>
  <v-row class="pa-3">
    <!-- Left Controls -->
    <v-col cols="2" class="pr-3">
      <v-expansion-panels v-model="panelsLeft" multiple>
        <!-- Map Data -->
        <v-expansion-panel>
          <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
            <strong>Map Data</strong>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-5">
            <v-row v-if="error" class="pa-3 red">{{ this.error }}</v-row>
            <!-- Mouse Position -->
            <v-row v-if="selectedLayer" align="center">
              <v-col cols="1" class="mr-3">
                <v-icon class="mb-1">mdi-cursor-default</v-icon>
              </v-col>
              <v-col>
                <div class="mb-3">Mouse Position: {{ hoverNode || (hoverX == 0 && hoverY == 0) ? "" : ("(" + hoverX + ", " + hoverY + ")") }}</div>
              </v-col>
            </v-row>
            <!-- Template -->
            <v-row v-if="mapTemplates.length > 0" align="center">
              <v-col cols="1" class="mr-3">
                <v-icon>mdi-map</v-icon>
              </v-col>
              <v-col>
                <v-select v-model="mapTemplate" @change="onSelectedMapTemplate" label="Template" :items="mapTemplates" :disabled="(!isAdmin && !userData.isAdmin) || loading" return-object></v-select>
              </v-col>
            </v-row>
            <!-- Name -->
            <v-row v-if="(isAdmin || userData.isAdmin) && mapTemplate" align="center">
              <v-col cols="1" class="mr-3">
                <v-icon>mdi-tag</v-icon>
              </v-col>
              <v-col>
                <v-text-field v-model="mapTemplate.text" label="Name"></v-text-field>
              </v-col>
            </v-row>
            <!-- Selected Players -->
            <v-row v-if="mapTemplate" align="center">
              <v-col cols="1" class="mr-3">
                <v-icon>mdi-account-multiple</v-icon>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedPlayers"
                  label="Players"
                  :items="playersData"
                  :menu-props="{closeOnContentClick:selectedPlayers.length===playersData.length||selectedPlayers.length===0}"
                  multiple
                  return-object
                >
                  <template slot="selection" slot-scope="{ item, index }">
                    <span v-if="!index">{{ item.text }}</span>
                    <span class="grey--text caption" v-else-if="index === 1">
                      {{'\xa0'}}(+{{ selectedPlayers.length - 1 }} other<span v-if="selectedPlayers.length > 2">s)</span><span v-else>)</span>
                    </span>
                  </template>
                  <template v-if="playersData && playersData.length > 0" v-slot:prepend-item>
                    <v-list-item @click="toggleSelectAllPlayers" @mousedown.prevent ripple>
                      <v-list-item-action>
                        <v-icon :color="selectedPlayers.length > 0 ? 'indigo darken-4' : ''">
                          {{ iconSelectedPlayers }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <!-- Controls -->
            <v-row justify="center">
              <!-- New -->
              <v-tooltip v-if="isAdmin || userData.isAdmin" top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="createMapTemplate" :loading="loading" v-on="on" color="blue">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <div>Create Template</div>
              </v-tooltip>
              <!-- Save -->
              <v-tooltip top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="saveMapData" :loading="loading" :disabled="!mapTemplate" v-on="on" class="ml-2 mr-2" color="red">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <div>Save All Changes</div>
              </v-tooltip>
              <!-- Delete -->
              <v-tooltip v-if="isAdmin || userData.isAdmin" top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="isShowingConfirmDeleteTemplateDialog=true" :loading="loading" :disabled="!mapTemplate" v-on="on" color="red">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <div>Delete Template</div>
              </v-tooltip>
              <!-- Confirm Delete Template Dialog -->
              <v-dialog v-model="isShowingConfirmDeleteTemplateDialog" width="500">
                <v-card class="pa-5">
                  <v-card-title>Delete Template</v-card-title>
                  <v-card-text class="mt-3">Are you sure you want to delete this map template <strong>and all associated player data?</strong></v-card-text>
                  <v-card-actions class="mt-3">
                    <v-btn @click="deleteMapTemplate" :disabled="loading" color="red">
                      <v-icon class="mr-2">mdi-trash-can</v-icon>
                      Delete
                    </v-btn>
                    <v-btn :disabled="loading" @click="isShowingConfirmDeleteTemplateDialog=false" class="ml-2">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Export CSV -->
              <!-- <v-tooltip v-if="isAdmin || userData.isAdmin || canExportMapData" top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="exportMapDataCSV" :loading="loading" :disabled="!mapTemplate" v-on="on" class="mr-1 mt-2" color="blue">
                    <v-icon>mdi-file-delimited</v-icon>
                  </v-btn>
                </template>
                <div>Export to CSV</div>
              </v-tooltip> -->
              <!-- Export JSON -->
              <v-tooltip v-if="isAdmin || userData.isAdmin || canExportMapData" top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="exportMapDataJSON" :loading="loading" :disabled="!mapTemplate" v-on="on" class="ml-1 mt-2" color="blue">
                    <v-icon>mdi-file-code</v-icon>
                  </v-btn>
                </template>
                <div>Export to JSON</div>
              </v-tooltip>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Layers -->
        <v-expansion-panel v-if="mapTemplate">
          <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
            <strong>Layers</strong>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-5">
            <!-- Layer Select -->
            <v-row align="center">
              <v-col cols="1">
                <v-icon>mdi-layers-triple</v-icon>
              </v-col>
              <v-col cols="11" class="pl-3">
                <v-select v-model="selectedLayer" @change="onSelectedLayer" label="Layer" :items="mapTemplate.layers" hide-details return-object></v-select>
              </v-col>
            </v-row>
            <!-- ID -->
            <v-text-field v-if="selectedLayer && (isAdmin || userData.isAdmin)" v-model="selectedLayer.id" label="ID" disabled></v-text-field>
            <!-- Name -->
            <v-text-field v-if="selectedLayer && (isAdmin || userData.isAdmin)" v-model="selectedLayer.text" label="Name"></v-text-field>
            <!-- Image -->
            <v-file-input v-if="selectedLayer && (isAdmin || userData.isAdmin)" v-model="selectedLayer.imageFile" @change="previewImageFile" label="Image" prepend-icon="mdi-camera" accept="image/*"></v-file-input>
            <!-- Controls -->
            <v-row v-if="isAdmin || userData.isAdmin" justify="center" class="mt-3" style="width: 100%;">
              <!-- Add -->
              <v-tooltip top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="createLayer" v-on="on" color="blue" class="mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <div>Add Layer</div>
              </v-tooltip>
              <!-- Delete -->
              <v-tooltip top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="isShowingConfirmDeleteLayerDialog=true" :disabled="loading || !selectedLayer || mapTemplate.layers.length === 1" v-on="on" color="red" class="ml-1">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <div>Delete Layer</div>
              </v-tooltip>
              <!-- Confirm Delete Layer Dialog -->
              <v-dialog v-model="isShowingConfirmDeleteLayerDialog" width="500">
                <v-card class="pa-5">
                  <v-card-title>Delete Layer</v-card-title>
                  <v-card-text class="mt-3">Are you sure you want to delete this layer <strong>and all nodes in this layer?</strong></v-card-text>
                  <v-card-actions class="mt-3">
                    <v-btn @click="deleteSelectedLayer" :disabled="loading" color="red">
                      <v-icon class="mr-2">mdi-trash-can</v-icon>
                      Delete
                    </v-btn>
                    <v-btn :disabled="loading" @click="isShowingConfirmDeleteLayerDialog=false" class="ml-2">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Node Categories -->
        <v-expansion-panel v-if="mapTemplate">
          <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
            <strong>Node Categories</strong>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-5">
            <!-- Category Select -->
            <v-row align="center">
              <v-col cols="1">
                <v-icon icon="fa-solid fa-circle" :color="hoverCategory ? hoverCategory.color : (selectedCategories.length > 0 ? selectedCategories[0].color : '#969696FF')">mdi-circle</v-icon>
              </v-col>
              <v-col cols="11" class="pl-3">
                <v-select
                  v-model="selectedCategories"
                  label="Categories"
                  :items="mapTemplate.categories"
                  :menu-props="{closeOnContentClick:selectedCategories.length===((mapTemplate.categories ? mapTemplate.categories.length : 0)||selectedCategories.length===0)}"
                  multiple
                  return-object
                  hide-details
                >
                  <template v-if="mapTemplate.categories && mapTemplate.categories.length > 0" v-slot:prepend-item>
                    <v-list-item @click="toggleSelectAllCategories" @mousedown.prevent ripple>
                      <v-list-item-action>
                        <v-icon :color="selectedCategories.length > 0 ? 'indigo darken-4' : ''">
                          {{ iconSelectedCategories }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          All
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template slot="selection" slot-scope="{ item, index }">
                    <span v-if="!index">{{ item.text }}</span>
                    <span class="grey--text caption" v-else-if="index === 1">
                      {{'\xa0'}}(+{{ selectedCategories.length - 1 }} other<span v-if="selectedCategories.length > 2">s)</span><span v-else>)</span>
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <!-- ID -->
            <v-text-field v-if="selectedCategories.length > 0 && (isAdmin || userData.isAdmin)" v-model="selectedCategories[0].id" label="ID" disabled></v-text-field>
            <!-- Name -->
            <v-text-field v-if="selectedCategories.length > 0 && (isAdmin || userData.isAdmin)" v-model="selectedCategories[0].text" label="Name"></v-text-field>
            <!-- Color -->
            <v-row v-if="selectedCategories.length > 0 && (isAdmin || userData.isAdmin)">
              <v-card class="grey darken-3 pa-5" style="width: 100%">
                <v-color-picker
                  v-model="selectedCategories[0].color"
                  dot-size="25"
                  mode='hexa'
                  swatches-max-height="200"
                  elevation="15"
                ></v-color-picker>
              </v-card>
            </v-row>
            <!-- Controls -->
            <v-row v-if="isAdmin || userData.isAdmin" justify="center" class="mt-3" style="width: 100%;">
              <!-- Add -->
              <v-tooltip top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="createCategory" :disabled="loading" v-on="on" color="blue" class="mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <div>Add Category</div>
              </v-tooltip>
              <!-- Delete -->
              <v-tooltip top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn @click="showConfirmDeleteCategoryDialog" :disabled="loading || !mapTemplate.categories || mapTemplate.categories.length <= 1" v-on="on" color="red" class="ml-1">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <div>Delete Category</div>
              </v-tooltip>
              <!-- Confirm Delete Categories Dialog -->
              <v-dialog v-model="isShowingConfirmDeleteCategoryDialog" width="500">
                <v-card v-if="selectedCategories.length > 0" class="pa-5">
                  <v-card-title>Delete Categories</v-card-title>
                  <v-card-text class="mt-3">Are you sure you want to delete the <strong>{{ selectedCategories[0].text }}</strong> category? Map nodes that use this category will use the following category instead:</v-card-text>
                  <v-row class="mt-3">
                    <v-select v-model="replacementCategory" label="Category" :items="replacementCategories" return-object></v-select>
                  </v-row>
                  <v-card-actions class="mt-3">
                    <v-btn @click="deleteSelectedCategory" :disabled="loading || !replacementCategory" color="red">
                      <v-icon class="mr-2">mdi-trash-can</v-icon>
                      Delete
                    </v-btn>
                    <v-btn @click="isShowingConfirmDeleteCategoryDialog=false" :disabled="loading" class="ml-2">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <!-- Map -->
    <v-col cols="8" style="height: 850px; overflow: auto;">
      <v-img v-if="selectedLayer"
        @click="onClickedMap"
        @mousemove="onMapMouseMove"
        @mouseleave="hoverX=0; hoverY=0"
        :src="selectedLayer.imagePath"
        position="top middle"
        :height="1024 * mapScale"
        :width="1024 * mapScale"
        :min-height="1024 * mapScale"
        :min-width="1024 * mapScale"
      >
        <div v-for="(player, i) in selectedPlayers" :key="i">
          <div v-for="(heatmap, i) in player.heatmaps" :key="i">
            <div v-if="heatmap.id == mapTemplate.id">
              <div v-for="(layer, i) in heatmap.layers" :key="i">
                <div v-if="layer.id == selectedLayer.id">
                  <div v-for="(node, i) in layer.nodes" :key="i">
                    <v-tooltip v-if="selectedCategories.length > 0 && selectedCategories.find(cat => cat.id == node.category)" top color="grey darken-4">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click.stop="selectedNode=node"
                          @mouseenter="hoverNode=node"
                          @mouseleave="hoverNode=null"
                          :color="mapTemplate.categories.find(cat => cat.id == node.category).color"
                          :class="[
                            selectedNode==node ? 'selected-node' : '',
                            hoverNode==node ? 'hover-node' : ''
                          ]"
                          :style="{
                            cursor: 'pointer',
                            fontSize: 8 * node.qty,
                            left: node.x * mapScale - (8 * node.qty / 2),
                            position: 'absolute',
                            top: node.y * mapScale - (8 * node.qty / 2)
                          }"
                        >mdi-circle</v-icon>
                      </template>
                      <div>Player: {{ player.text }}</div>
                      <div>{{ node.text }}</div>
                      <div>{{ "(" + node.x + ", " + node.y + ")" }}</div>
                      <div>{{ "Quantity: " + node.qty }}</div>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </v-col>
    <!-- Right Controls -->
    <v-col cols="2" class="pl-3">
      <v-expansion-panels v-model="panelsRight" multiple>
        <!-- Zoom -->
        <v-expansion-panel v-if="playersData.length > 0">
          <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
            <strong>Zoom</strong>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-5">
            <v-slider
              v-model="mapScale"
              :max="mapScaleMax"
              :min="mapScaleMin"
              :step="mapScaleStep"
              ticks
              tick-size="4"
              :tick-labels="['.5', '', '1', '', '1.5', '', '2']"
              append-icon="mdi-magnify-plus-outline"
              prepend-icon="mdi-magnify-minus-outline"
              @click:append="zoomIn"
              @click:prepend="zoomOut"
            ></v-slider>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Edit Node -->
        <v-expansion-panel class="mt-3">
          <v-expansion-panel-header v-if="selectedNode" class="white--text" style="background-color: #2B3C4F;">
            <strong>Edit Node</strong>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="selectedNode" class="pa-5">
            <!-- Node Selection -->
            <v-row v-if="visibleNodes.length > 0" align="center" class="mb-3">
              <v-col justify="left" cols="3">
                <v-btn @click="selectNodePrev" :disabled="loading">
                  <v-icon>mdi-arrow-left-bold</v-icon>
                </v-btn>
              </v-col>
              <v-col class="text-center">
                {{ (visibleNodes.indexOf(selectedNode) + 1) + ' of ' + visibleNodes.length }}
              </v-col>
              <v-col justify="right" cols="3">
                <v-btn @click="selectNodeNext" :disabled="loading">
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- Label -->
            <v-row align="center">
              <v-col cols="1">
                <v-icon>mdi-tag</v-icon>
              </v-col>
              <v-col cols="11" class="pl-3">
                <v-text-field v-model="selectedNode.text" label="Label" :disabled="!thisPlayerOwnsSelectedNode()"></v-text-field>
              </v-col>
            </v-row>
            <!-- Category -->
            <v-row align="center">
              <v-col cols="1">
                <v-icon>mdi-circle</v-icon>
              </v-col>
              <v-col cols="11" class="pl-3">
                <v-select v-model="selectedNode.category" item-value="id" label="Category" :items="mapTemplate.categories" :disabled="!thisPlayerOwnsSelectedNode()"></v-select>
              </v-col>
            </v-row>
            <!-- Coordinates -->
            <v-row>
              <v-col cols="6" class="pr-1">
                <v-text-field v-model="selectedNode.x" label="X" :disabled="!thisPlayerOwnsSelectedNode()" type="number" min="0"></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-1">
                <v-text-field v-model="selectedNode.y" label="Y" :disabled="!thisPlayerOwnsSelectedNode()" type="number" min="0"></v-text-field>
              </v-col>
            </v-row>
            <!-- Quantity -->
            <v-row>
              <v-text-field v-model="selectedNode.qty" label="Quantity" :disabled="!thisPlayerOwnsSelectedNode()" type="number" min="1"/>
            </v-row>
            <!-- Notes -->
            <v-row>
              <v-textarea v-model="selectedNode.notes" label="Notes" :disabled="!thisPlayerOwnsSelectedNode()" rows="4"></v-textarea>
            </v-row>
            <!-- Controls -->
            <v-row justify="center">
              <v-tooltip top color="grey darken-1">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="red" :disabled="loading || !thisPlayerOwnsSelectedNode()" @click="isShowingConfirmDeleteNodeDialog=true">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <div>Delete Node</div>
              </v-tooltip>
              <!-- Confirm Delete Node Dialog -->
              <v-dialog v-model="isShowingConfirmDeleteNodeDialog" width="500">
                <v-card class="pa-5">
                  <v-card-title>Delete Node</v-card-title>
                  <v-card-text>Are you sure you wanted to delete the node at {{ "(" + selectedNode.x + ", " + selectedNode.y + ")" }}?</v-card-text>
                  <v-card-actions class="mt-3">
                    <v-btn @click="deleteSelectedNode" :disabled="loading" color="red">
                      <v-icon class="mr-2">mdi-trash-can</v-icon>
                      Delete
                    </v-btn>
                    <v-btn :disabled="loading" @click="isShowingConfirmDeleteNodeDialog=false" class="ml-2">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Heatmap',

    props: ['templateID', 'userID', 'isAdmin'],

    data () { 
      return {
        canExportMapData: false,
        error: '',
        hoverCategory: null,
        hoverNode: null,
        hoverX: 0,
        hoverY: 0,
        isShowingConfirmDeleteCategoryDialog: false,
        isShowingConfirmDeleteLayerDialog: false,
        isShowingConfirmDeleteNodeDialog: false,
        isShowingConfirmDeleteTemplateDialog: false,
        loading: false,
        mapID: '',
        mapScale: 1,
        mapScaleMax: 2,
        mapScaleMin: .5,
        mapScaleStep: .25,
        mapTemplate: null,
        mapTemplates: [],
        panelsLeft: [0, 1, 2],
        panelsRight: [0, 1],
        playerData: null,
        playerID: null,
        playersData: [],
        replacementCategories: [],
        replacementCategory: null,
        selectedCategories: [],
        selectedLayer: null,
        selectedNode: null,
        selectedPlayers: []
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ]),
      iconSelectedCategories () {
        if (this.mapTemplate.categories && this.selectedCategories.length === this.mapTemplate.categories.length) return 'mdi-close-box'
        if (this.selectedCategories.length === 0) return 'mdi-checkbox-blank-outline'
        return 'mdi-minus-box'
      },
      iconSelectedPlayers () {
        if (this.selectedPlayers.length === this.playersData.length) return 'mdi-close-box'
        if (this.selectedPlayers.length === 0) return 'mdi-checkbox-blank-outline'
        return 'mdi-minus-box'
      },
      visibleNodes () {
        var nodes = []
        this.selectedPlayers.forEach(playerData => {
          playerData.heatmaps?.forEach(map => {
            map.layers?.forEach(layer => {
              layer.nodes?.forEach(node => {
                this.selectedCategories.forEach(cat => {
                  if (cat.id == node.category) nodes.push(node)
                })
              })
            })
          })
        })

        nodes = nodes.sort((a, b) => a.x > b.x ? 1 : -1)

        return nodes
      }
    },

    created () {
      this.getHeatmapTemplates()
    },

    methods: {
      addNodeToPlayerData () {
        if (!this.playerData.heatmapTemplates) this.playerData.heatmapTemplates = []
        if (!this.playerData.heatmapTemplates.find(templateID => templateID == this.mapTemplate.id)) this.playerData.heatmapTemplates.push(this.mapTemplate.id)

        if (!this.playerData.heatmaps) this.playerData.heatmaps = []

        var thisMapData = this.playerData.heatmaps.find(map => map.id == this.mapTemplate.id)
        if (!thisMapData) {
          thisMapData = {
            id: this.mapTemplate.id,
            layers: []
          }
          this.playerData.heatmaps = this.playerData.heatmaps.concat(thisMapData)
        }
          
        var thisLayerData = thisMapData.layers.find(layer => layer.id == this.selectedLayer.id)
        if (!thisLayerData) {
            thisLayerData = {
            id: this.selectedLayer.id,
            nodes: []
          }
          thisMapData.layers = thisMapData.layers.concat(thisLayerData)
        }

        thisLayerData.nodes = thisLayerData.nodes.concat(this.selectedNode)

        // Select this player
        if (!this.selectedPlayers.find(player => player.id == this.playerData.id)) {
          this.selectedPlayers = this.selectedPlayers.concat(this.playerData)
        }

        this.$forceUpdate()
      },

      createCategory () {
        // Create the category object
        const newCategory = {
          id: '0',
          color: '#FFFFFFFF',
          text: 'New Category'
        }

        // Set ID of the new category if needed
        if (this.mapTemplate.categories) {
          // Look at each category in the template to see if the ID is a duplicate
          for (var i = 0; i < this.mapTemplate.categories.length; i++) {
            if (this.mapTemplate.categories[i].id == newCategory.id) {
              newCategory.id = (parseInt(newCategory.id) + 1).toString() // increment by 1
              i = 0 // look at each category again
            }
          }
          this.mapTemplate.categories = this.mapTemplate.categories.concat(newCategory) // using push won't work for some reason...
        } else {
          this.mapTemplate.categories = [newCategory]
        }

        this.selectedCategories = [newCategory]
        this.$forceUpdate()
      },

      createLayer () {
        // Create the layer object
        const newLayer = {
          id: '0',
          text: 'New Layer'
        }

        // Set ID of the new layer if needed
        if (this.mapTemplate.layers) {
          // Look at each layer in the template to see if the ID is a duplicate
          for (var i = 0; i < this.mapTemplate.layers.length; i++) {
            if (this.mapTemplate.layers[i].id == newLayer.id) {
              newLayer.id = (parseInt(newLayer.id) + 1).toString() // increment by 1
              i = 0 // look at each layer again
            }
          }
          this.mapTemplate.layers = this.mapTemplate.layers.concat(newLayer) // using push won't work for some reason...
        } else {
          this.mapTemplate.layers = [newLayer]
        }

        this.selectedLayer = newLayer
        this.onSelectedLayer()
        this.$forceUpdate()
      },

      createMapTemplate () {
        var newTemplate = {
          text: 'New Template'
        }

        // Save template to db so it gains a uniquely generated ID
        this.loading = true
        this.$store.dispatch('createHeatmapTemplate', newTemplate)
        .then((resp) => {
          newTemplate = resp
          this.loading = false
          this.mapTemplates.push(newTemplate)
          this.mapTemplate = newTemplate;
          this.onSelectedMapTemplate()
          this.$forceUpdate()
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      deleteMapTemplate () {
        // Remove template from players
        var promises = []
        var templateIndex
        this.playersData.forEach(playerData => {
          for (var i = playerData.heatmaps.length - 1; i >= 0; i--) {
            if (playerData.heatmaps[i].id == this.mapTemplate.id) {
              playerData.heatmaps.splice(i, 1)
              templateIndex = playerData.heatmapTemplates.indexOf(this.mapTemplate.id)
              if (templateIndex > -1) playerData.heatmapTemplates.splice(templateIndex, 1)

              // Update players in db
              promises.push(this.$store.dispatch('updateUserDataOther', { userData: playerData }))
            }
          }
        })

        this.loading = true
        Promise.all(promises)
        .then(() => {
          // Remove template from list
          templateIndex = this.mapTemplates.indexOf(this.mapTemplate)
          if (templateIndex > -1) this.mapTemplates.splice(templateIndex, 1)

          // Delete template from db
          this.$store.dispatch('deleteHeatmapTemplate', this.mapTemplate.id)
          .then(() => {
            // TODO: delete map files from storage
            this.mapTemplate = null
            this.selectedPlayers = []
            this.selectedLayer = null
            this.selectedCategories = null
            this.selectedNode = null
            this.isShowingConfirmDeleteTemplateDialog = false
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      deleteSelectedCategory () {
        // Set node replacements
        this.playersData.forEach(playerData => {
          playerData.heatmaps.forEach(map => {
            if (map.id == this.mapTemplate.id) {
              map.layers.forEach(layer => {
                layer.nodes.forEach(node => {
                  if (node.category === this.selectedCategories[0].id) {
                    node.category = this.replacementCategory.id
                  }
                })
              })
            }
          })
        })

        // Remove from template
        this.mapTemplate.categories = this.mapTemplate.categories.filter(cat => cat.id != this.selectedCategories[0].id)

        this.selectedCategories = [this.mapTemplate.categories[0]]
        this.isShowingConfirmDeleteCategoryDialog = false
      },

      deleteSelectedLayer () {
        // Delete nodes
        this.playersData.forEach(playerData => {
          playerData.heatmaps.forEach(map => {
            if (map.id == this.mapTemplate.id) {
              map.layers.forEach(layer => {
                if (layer.id == this.selectedLayer.id && layer.nodes) {
                  layer.nodes = []
                }
              })
            }
          })
        })

        // Remove from template
        this.mapTemplate.layers = this.mapTemplate.layers.filter(layer => layer.id != this.selectedLayer.id)
        this.selectedLayer = this.mapTemplate.layers[0]
        this.isShowingConfirmDeleteLayerDialog = false
      },

      deleteSelectedNode () {
        var thisMapData = this.playerData.heatmaps.find(map => map.id == this.mapTemplate.id)
        thisMapData.layers.forEach(layer => {
          layer.nodes.forEach(node => {
            if (node === this.selectedNode) {
              layer.nodes.splice(layer.nodes.indexOf(node), 1)
              this.selectedNode = null
              this.isShowingConfirmDeleteNodeDialog = false
              return
            }
          })
        })
      },

      exportMapDataCSV () {
        const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        const header = Object.keys(this.playersData[0].heatmaps)
        let csv = this.playersData[0].heatmaps.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')

        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', 'heatmap');
        link.click();
      },

      exportMapDataJSON () {
        // Only include relevant data in export
        var finalData = []
        var data
        for (var i = 0; i < this.playersData.length; i++) {
          data = this.playersData[i]
          finalData.push({
            id: data.id,
            email: data.email,
            text: data.text,
            heatmaps: data.heatmaps
          })
        }
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(finalData)));
        link.setAttribute('download', 'mapdata.json');
        link.click();
      },

      getHeatmapTemplates (newTemplateID = null) {
        this.loading = true
        this.$store.dispatch('getHeatmapTemplates')
        .then((resp) => {
          this.loading = false
          this.mapTemplates = resp.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)

          // If template ID and user ID were specified, check that user's map data
          const templateID = newTemplateID || this.$route.query.templateID || this.templateID
          this.playerID = this.$route.query.userID || this.userID || this.userData.id
          if (templateID && this.playerID) {
            this.$store.dispatch('getUsersByHeatmapTemplateID', templateID)
            .then((resp) => {
              this.loading = false
              if (resp.length > 0) {
                const thisUserData = resp.find(user => user.id === this.playerID)
                const thisMapData = thisUserData.heatmaps.find(map => map.id === templateID)
                if (!thisMapData.expirationDate || new Date() < new Date(thisMapData.expirationDate)) {
                  this.playersData = resp.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
                  this.playerData = thisUserData
                  this.canExportMapData = thisMapData.canExport
                  this.mapTemplate = this.mapTemplates.find(template => template.id === templateID)
                  this.selectedCategories = this.mapTemplate.categories || []
                  this.selectedLayer = (this.mapTemplate.layers && this.mapTemplate.layers.length > 0) ? this.mapTemplate.layers[0] : null
                  this.selectedPlayers = [thisUserData]
                } else {
                  this.error = "Access to this heatmap data has been restricted."
                }
              } else {
                this.error = "No users found with map data that matches this template ID."
              }
            })
            .catch((error) => {
              this.loading = false
              this.error = error
            })
          }
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      onClickedMap (event) {
        if (!this.selectedLayer || !this.selectedLayer.imagePath || this.loading) return

        // Create and select node
        this.selectedNode = {
          text: 'New Node',
          category: (this.selectedCategories && this.selectedCategories.length > 0) ? this.selectedCategories[0].id : '',
          notes: '',
          qty: 1,
          x: Math.round(event.offsetX / this.mapScale),
          y: Math.round(event.offsetY / this.mapScale)
        }

        // Add it to this player's map data
        if (this.playerData) {
          this.addNodeToPlayerData()
        } else {
          this.loading = true
          const playerID = this.playerID
          this.$store.dispatch('getUserDataOther', { id: playerID })
          .then((resp) => {
            this.loading = false
            this.playerData = resp
            this.playersData = this.playersData.concat(this.playerData)
            this.addNodeToPlayerData()
          })
          .catch((error) => {
            this.loading = false
            this.error = error
          })
        }
      },

      onMapMouseMove (event) {
        this.hoverX = Math.round(event.offsetX / this.mapScale)
        this.hoverY = Math.round(event.offsetY / this.mapScale)
      },

      onSelectedLayer () {
        this.selectedNode = null
      },

      onSelectedMapTemplate () {
        this.selectedCategories = this.mapTemplate.categories || []
        this.selectedLayer = (this.mapTemplate.layers && this.mapTemplate.layers.length > 0) ? this.mapTemplate.layers[0] : null
        this.selectedPlayers = []

        if (this.mapTemplate.id) {
          this.loading = true
          this.$store.dispatch('getUsersByHeatmapTemplateID', this.mapTemplate.id)
          .then((resp) => {
            this.loading = false
            this.playersData = resp.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1)
            this.playerData = this.playersData.find(pData => pData.id == this.playerID)
            this.selectedPlayers = this.playersData
          })
          .catch((error) => {
            this.loading = false
            this.error = error
          })
        } else {
          this.playersData = []
        }
      },

      previewImageFile () {
        this.selectedLayer.imagePath = this.selectedLayer.imageFile ? URL.createObjectURL(this.selectedLayer.imageFile) : ''
      },

      saveMapData () {
        // Save template
        this.loading = true
        this.$store.dispatch('updateHeatmapTemplate', this.mapTemplate)
        .then(() => {
          // Save player data
          if (this.playerData) {
            const playerData = this.playerData
            this.$store.dispatch('updateUserDataOther', { userData: playerData })
            .then(() => {
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.error = error
            })
          } else {
            this.loading = false
          }
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      selectNodeNext () {
        var currIndex = this.visibleNodes.indexOf(this.selectedNode)
        var nextIndex = currIndex == this.visibleNodes.length - 1 ? 0 : currIndex + 1
        this.selectedNode = this.visibleNodes[nextIndex]
      },

      selectNodePrev () {
        var currIndex = this.visibleNodes.indexOf(this.selectedNode)
        var nextIndex = currIndex == 0 ? this.visibleNodes.length - 1 : currIndex - 1
        this.selectedNode = this.visibleNodes[nextIndex]
      },

      showConfirmDeleteCategoryDialog () {
        this.replacementCategories = [].concat(this.mapTemplate.categories).filter(cat => cat.id != this.selectedCategories[0].id);
        this.replacementCategory = null
        this.isShowingConfirmDeleteCategoryDialog = true
      },

      thisPlayerOwnsSelectedNode () {
        if (!this.playerData) return false

        var thisMapData = this.playerData.heatmaps.find(map => map.id == this.mapTemplate.id)
        if (thisMapData) {
          var thisLayerData = thisMapData.layers.find(layer => layer.id = this.selectedLayer.id)
          if (thisLayerData) {
            return thisLayerData.nodes.indexOf(this.selectedNode) > -1
          }
        }
      },

      toggleSelectAllCategories () {
        this.$nextTick(() => {
          if (this.selectedCategories.length === this.mapTemplate.categories.length) {
            this.selectedCategories = []
          } else {
            this.selectedCategories = this.mapTemplate.categories
          }
        })
      },

      toggleSelectAllPlayers () {
        this.$nextTick(() => {
          if (this.selectedPlayers.length === this.playersData.length) {
            this.selectedPlayers = []
          } else {
            this.selectedPlayers = this.playersData
          }
        })
      },

      zoomIn () {
        this.mapScale += this.mapScaleStep
        if (this.mapScale > this.mapScaleMax) this.mapScale = this.mapScaleMax
      },

      zoomOut () {
        this.mapScale -= this.mapScaleStep
        if (this.mapScale < this.mapScaleMin) this.mapScale = this.mapScaleMin
      }
    }
  }
</script>

<style scoped>
  .hover-node {
    filter: drop-shadow(white 1px 0px 0px) 
            drop-shadow(white 0px 1px 0px) 
            drop-shadow(white -1px 0px 0px) 
            drop-shadow(white 0px -1px 0px);
    font-size: 30px;
    outline: none;
  }

  .selected-node {
    filter: drop-shadow(yellow 3px 0px 0px) 
            drop-shadow(yellow 0px 3px 0px) 
            drop-shadow(yellow -3px 0px 0px) 
            drop-shadow(yellow 0px -3px 0px);
    font-size: 30px;
    outline: none;
  }

  .v-expansion-panel::v-deep .v-expansion-panel-header {
    margin: 0 !important;
    padding: 16px !important;
    min-height: 0;
  }

  .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
</style>