<template>
  <v-row justify="center">
    <!-- Header -->
    <v-col cols="12">
      <!-- Loading -->
      <v-row v-if="loading" class="pt-5" justify="center">
        <v-icon>mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Error -->
      <v-row v-if="error" class="pt-5 red--text" justify="center">
        {{ error }}
      </v-row>
      <!-- Controls -->
      <v-row v-if="userData && userData.isAdmin && projectData.idProject" class="pt-5" justify="center">
        <!-- Edit -->
        <v-btn @click="isEditing=true" :disabled="isEditing" class="mr-2 white--text" color="primaryButton" style="width: 128px;">
          <v-icon class="mr-2">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <!-- Cancel -->
        <v-btn @click="isEditing=false" :disabled="!isEditing" class="ml-2 white--text" color="primaryButton" style="width: 128px;">
          Cancel
        </v-btn>
      </v-row>
    </v-col>
    <!-- Project Form -->
    <v-col v-if="isEditing && !loading" cols="5" class="pb-5 pt-5">
      <ProjectForm :inputData="projectData" v-on:onSubmitComplete="onSubmitComplete"></ProjectForm>
    </v-col>
    <!-- Project View -->
    <v-col v-else-if="!loading" cols="12" class="pa-5 pt-0">
      <!-- Header -->
      <v-row class="mb-5 mt-5">
        <!-- Project Thumbnail -->
        <v-col cols="4" class="flex-grow-1 flex-shrink-0" style="min-width: 100px; max-width: 100%;">
          <ProjectThumbnail v-if="projectData != {}" :projectData="projectData"></ProjectThumbnail>
        </v-col>
        <!-- Documents -->
        <v-col cols="3" class="flex-grow-1 flex-shrink-0 ml-5 mr-5">
          <v-card style="min-height: 100%;">
            <v-row justify="center" style="background-color: #2B3C4F;">
              <h3 class="pa-3 white--text">Files</h3>
            </v-row>
            <v-row class="pa-5">
              <v-col align="left" v-if="projectData.documents && projectData.documents.length > 0">
                <div v-for="(doc, i) in projectData.documents" :key="i">
                  <div v-if="doc.type != 'Summary Document'">
                  <v-icon v-if="doc.loading" class="mr-2">mdi mdi-loading mdi-spin</v-icon>
                  <u><a @click="downloadDocument(doc)" :href="doc.blobURL" :download="doc.name">{{ doc.name }}</a></u>
                  </div>
                </div>
              </v-col>
              <div v-else>No files uploaded.</div>
            </v-row>
          </v-card>
        </v-col>
        <!-- Quick Links -->
        <v-col cols="3" class="flex-grow-1 flex-shrink-0">
          <v-card style="min-height: 100%;">
            <v-row justify="center" style="background-color: #2B3C4F;">
              <h3 class="pa-3 white--text">Quick Links</h3>
            </v-row>
            <v-row class="pa-5">
              <v-col align="left">
                <div><a href="#" v-if="projectSummaryDocs.length > 0" @click="showSection(0)" v-scroll-to="'#sectionSummaryDocs'">Summary Documents</a></div>
                <div><a href="#" v-if="getProjectLinks('Power BI URL').length > 0" @click="showSection(1)" v-scroll-to="'#sectionProjectReport'">DAQA Interactive Test Report{{ projectData.powerBiURLs && projectData.powerBiURLs.length > 0 ? 's' : '' }}</a></div>
                <div><a href="#" v-if="getProjectLinks('Spreadsheet URL').length > 0" @click="showSection(2)" v-scroll-to="'#sectionSpreadsheet'">Spreadsheet</a></div>
                <div><a href="#" v-if="getProjectLinks('Project Form').length > 0" @click="showSection(3)" v-scroll-to="'#sectionForms'">Forms</a></div>
                <div><a href="#" v-if="getProjectLinks('Video Recordings').length > 0" @click="showSection(4)" v-scroll-to="'#sectionVideoRecordings'">Video Recordings</a></div>
                <div><a href="#" v-if="getProjectLinks('Heatmaps').length > 0" @click="showSection(5)" v-scroll-to="'#sectionHeatmaps'">Heatmaps</a></div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- Expansion Panels -->
      <v-row no-gutters>
        <v-expansion-panels v-model="panel" focusable multiple>
          <!-- Summary Documents -->
          <v-expansion-panel id="sectionSummaryDocs" v-if="projectSummaryDocs.length > 0">
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Summary Documents</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Tabs -->
              <v-tabs v-model="tabSummaryDocs" class="pa-3 pb-5" fixed-tabs>
                <v-tab v-for="(doc, i) in projectSummaryDocs" :key="i" >{{ doc.name || ('Document ' + (i + 1)) }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabSummaryDocs">
                <v-tab-item v-for="(doc, i) in projectSummaryDocs" :key="i" >
                  <!-- <embed :src="doc.url" height="1200" style="width: 100%" type="application/pdf"> -->
                  <iframe :src="doc.url" type="application/pdf" width="100%" height="800px" style="overflow: auto;">
                  </iframe>
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Project Reports -->
          <v-expansion-panel id="sectionProjectReport" v-if="getProjectLinks('Power BI URL').length > 0" height="500">
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>DAQA Interactive Test Report{{ getProjectLinks('Power BI URL').length > 1 ? 's' : '' }}</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <!-- Tabs -->
              <v-tabs v-model="tabProjectReports" class="pa-3 pb-5" fixed-tabs>
                <v-tab v-for="(report, i) in getProjectLinks('Power BI URL')" :key="i" >{{ report.name || ('Report ' + (i + 1)) }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabProjectReports">
                <v-tab-item v-for="(report, i) in getProjectLinks('Power BI URL')" :key="i">
                  <iframe :src="report.url" frameborder="0" class="pbiframe" height="1024" style="width: 100%"></iframe>
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Spreadsheet -->
          <v-expansion-panel id="sectionSpreadsheet" v-if="getProjectLinks('Spreadsheet URL').length > 0">
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Spreadsheet</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
              <iframe :src="getProjectLinks('Spreadsheet URL')[0].url" frameborder="0" height="600" style="width: 100%"></iframe>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Forms -->
          <v-expansion-panel id="sectionForms" v-if="getProjectLinks('Project Form').length > 0">
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Forms</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Tabs -->
              <v-tabs v-model="tabForms" class="pa-3 pb-5" fixed-tabs>
                <v-tab v-for="(form, i) in getProjectLinks('Project Form')" :key="i" >{{ form.name || ('Form ' + (i + 1)) }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabForms">
                <v-tab-item v-for="(form, i) in getProjectLinks('Project Form')" :key="i" >
                  <iframe :src="form.url" frameborder="0" height="600" style="width: 100%"></iframe>
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Video Recordings -->
          <v-expansion-panel id="sectionVideoRecordings" v-if="getProjectLinks('Video Recordings').length > 0">
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Video Recordings</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- Tabs -->
              <v-tabs v-model="tabVideoRecordings" class="pa-3 pb-5" fixed-tabs>
                <v-tab v-for="(video, i) in getProjectLinks('Video Recordings')" :key="i" >{{ video.text || ('Video ' + (i + 1)) }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabVideoRecordings">
                <v-tab-item v-for="(video, i) in getProjectLinks('Video Recordings')" :key="i" >
                  <div style="max-width: 640px">
                    <div style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
                      <iframe :src="video.url" width="640" height="360" frameborder="0" scrolling="no" allowfullscreen title="2022-10-05_20-15-39.mp4" style="border:none; position: absolute; top: 0; left: 0; right: 0; bottom: 0; height: 100%; max-width: 100%;"></iframe>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Heatmaps -->
          <v-expansion-panel id="sectionHeatmaps" v-if="heatmapTemplates.length > 0 && getProjectLinks('Heatmaps').length > 0">
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Heatmaps</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="black">
              <!-- Tabs -->
              <v-tabs v-model="tabHeatmaps" class="pa-3 pb-5" fixed-tabs>
                <v-tab v-for="(mapID, i) in getProjectLinks('Heatmaps')" :key="i" >{{ heatmapTemplates.find(template => template.id === mapID).text }}</v-tab>
              </v-tabs>
              <v-tabs-items class="black" v-model="tabHeatmaps">
                <v-tab-item v-for="(mapID, i) in getProjectLinks('Heatmaps')" :key="i" >
                  <Heatmap :templateID="mapID" :userID="userData.id" :isAdmin="true"></Heatmap>
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-col>
    <!-- Confirm Delete Project Dialog -->
    <v-dialog v-model="isShowingConfirmDeleteProjectDialog" width="500">
      <v-card class="pa-5">
        <v-card-title class="ma-0 pa-0">Delete Project</v-card-title>
        <v-card-text class="ma-0 pa-0 pt-5">Are you sure you want to delete this project?</v-card-text>
        <v-card-actions class="ma-0 pa-0 pt-5">
          <v-btn @click="deleteProject" :loading="loading" color="red white--text" style="width: 128px;">
            <v-icon class="mr-2">mdi-trash-can</v-icon>
            Delete
          </v-btn>
          <v-btn @click="isShowingConfirmDeleteProjectDialog=false" :disabled="loading" color="primaryButton" style="width: 128px;">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Heatmap from "@/components/Heatmap"
  import ProjectForm from "@/components/ProjectForm"
  import ProjectThumbnail from "@/components/ProjectThumbnail"

  export default {
    name: 'Project',

    components: {
      Heatmap,
      ProjectForm,
      ProjectThumbnail
    },

    data () {
      return {
        error: '',
        heatmapTemplates: [],
        isEditing: false,
        isShowingConfirmDeleteProjectDialog: false,
        loading: false,
        panel: [],
        projectData: {},
        projectSummaryDocs: [],
        tabHeatmaps: '',
        tabForms: '',
        tabProjectReports: '',
        tabSummaryDocs: '',
        tabVideoRecordings: '',
        tabInterviews: ''
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    created () {
      this.getProjectData()
    },

    methods: {
      deleteProject () {
        this.$store.dispatch('deleteProject', { projectID: this.projectData.idProject })
        .then(() => {
          this.isShowingConfirmDeleteProjectDialog = false
          this.$router.push('/projects')
        })
        .catch((err) => {
          this.error = err
          this.isShowingConfirmDeleteProjectDialog = false
        })
      },

      downloadDocument(doc) {
        if (doc.loading) return
        doc.loading = true
        this.$forceUpdate()
        const isOpen = doc.name.indexOf('.pdf') > -1 || doc.name.indexOf('.PDF') > -1
        this.$store.dispatch('getProjectFile', { retrieveType: isOpen ? 'display' : 'download', uuid: doc.uuid })
        .then((blobURL) => {
          if (isOpen) {
            let tab = window.open()
            tab.location.href = blobURL
            tab.location.download = doc.name
          } else {
            const a = document.createElement('a')
            a.href = blobURL
            a.download = doc.name  // Use the filename
            a.click()
          }
          
          doc.loading = false
          this.$forceUpdate()
        })
        .catch((error) => {
          this.error = error
          doc.loading = false
          if (this.error.code == 'permission-denied') {
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/login')
            })
          }
          this.$forceUpdate()
        })
      },

      getHeatmapTemplates () {
        this.loading = true
        this.$store.dispatch('getHeatmapTemplates')
        .then((resp) => {
          this.loading = false
          this.heatmapTemplates = resp
          this.getProjectData()
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      getProjectData () {
        const projectID = this.$route.query.id
        if (projectID) {
          this.loading = true
          this.$store.dispatch('getProject', projectID)
          .then((resp) => {
            this.projectData = resp
            this.isEditing = false
            this.loading = false
            this.getProjectSummaryDocs()
          })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        } else {
          this.projectData = { } // new project
          this.isEditing = true
        }
      },

      getProjectSummaryDocs () {
        if (this.projectData.documents && this.projectData.documents.length > 0) {
          this.loading = true
          this.projectSummaryDocs = this.projectData.documents.filter(document => document.type == "Summary Document")

          var promises = []
          for (var i = 0; i < this.projectSummaryDocs.length; i++) {
            promises.push(this.$store.dispatch('getProjectFile', { retrieveType: 'display', uuid: this.projectSummaryDocs[i].uuid }))
          }

          this.loading = true
          Promise.all(promises)
          .then((responses) => {
            for (var i = 0; i < this.projectSummaryDocs.length; i++) {
              this.projectSummaryDocs[i].url = responses[i]
            }
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        }
      },

      getProjectLinks (type) {
        var value = []
        if (this.projectData.links && this.projectData.links.length > 0) {
          value = this.projectData.links.filter(link => link.type == type)
        }

        return value
      },

      onSubmitComplete (newProjectData) {
        this.projectData = newProjectData
        this.isEditing = false
        this.getProjectSummaryDocs()
      },

      showSection (index) {
        const currPanelIndex = this.panel.indexOf(index)
        if (currPanelIndex > -1) this.panel.splice(currPanelIndex, 1);
        this.panel.push(index)
      }
    }
  }
</script>

<style scoped>
  .pbiframe {
    clip-path: inset(0px 0px 60px 0px);
  }
</style>