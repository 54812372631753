<template>
  <v-card class="pa-5 w-100" align="center">
    <h2 class="text-center">{{ inputData.idProject ? 'Edit' : 'Create' }} Project</h2>
    <v-form v-model="valid" ref="form" @submit.prevent="submit">
      <!-- Submit -->
      <v-row justify="center" class="mt-5">
        <v-btn :type="'submit'" :loading="loading" color="primaryButton" class="white--text">
          Submit
        </v-btn>
      </v-row>
      <v-row v-if="error" justify="center" class="mt-5 red--text">
        {{ error }}
      </v-row>
      <v-row class="flex-wrap mt-5">
        <!-- Logo Upload -->
        <v-col class="col-12 col-sm-6 pa-2">
          <v-file-input v-model="logoFile" label="Logo" prepend-icon="mdi-camera" accept="image/*"></v-file-input>
        </v-col>
        <!-- Name -->
        <v-col class="col-12 col-sm-6 pa-2">
          <v-text-field v-model="formData.name" label="Project Name" :rules="requiredRules" :hint="formData.idProject == undefined ? '' : 'Check with sysadmin to change project name'" persistent-hint :disabled="formData.idProject != undefined"></v-text-field>
        </v-col>
        <!-- Publishers -->
        <v-col class="col-12 col-sm-6 pa-2">
          <v-select
            v-model="formData.publishers"
            :items="companies"
            item-value="idCompany"
            item-text="name"
            :rules="requiredRules"
            :menu-props="{ maxHeight: '400' }"
            label="Publishers"
            hint="Required"
            persistent-hint
            multiple
            clearable
          ></v-select>
        </v-col>
        <!-- Developers -->
        <v-col class="col-12 col-sm-6 pa-2">
          <v-select
            v-model="formData.developers"
            :items="companies"
            item-value="idCompany"
            item-text="name"
            :rules="requiredRules"
            :menu-props="{ maxHeight: '400' }"
            label="Developers"
            hint="Required"
            persistent-hint
            multiple
            clearable
          ></v-select>
        </v-col>
        <!-- Date -->
        <v-col align-self="center" class="col-12 col-sm-6 pa-2">
          <date-picker
            v-model="formData.startDateTime"
            type="date"
            style="width: 100%;"
            clearable
            use12h
            :show-second="false"
          >
            <template v-slot:input>
              <v-text-field
                label="Date"
                :value="getStartDateTimeString()" 
                :rules="requiredRules"
                class="date-select"
              ></v-text-field>
            </template>
          </date-picker>
        </v-col>
        <!-- Duration -->
        <v-col class="col-12 col-sm-6 pa-2">
          <v-text-field v-model="formData.duration" label="Duration" :type="'number'" hint="Hours" persistent-hint></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-expansion-panels v-model="panel" focusable multiple>
          <!-- Platforms -->
          <v-expansion-panel>
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Platforms</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <v-row v-for="(platform, i) in formData.platforms" :key="i" align="center">
                <v-col>
                  <v-select
                    v-model="platform.idPlatform"
                    :items="platforms"
                    item-text="name"
                    item-value="idPlatform"
                    label="Platform" 
                    menu-props="{ maxHeight: '400' }"
                    class="mr-2"
                    :rules="requiredRules"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    :value="platform.cohortSize"
                    @input="platform.cohortSize = computeCohortSize($event)"
                    label="Cohort Size"
                    type="number"
                    class="ml-2 mr-2"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col class="flex-grow-0 flex-shrink-1">
                  <v-btn @click="removePlatform(i)" class="darken-1 ml-2 red white--text">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn @click="addPlatform" color="primaryButton" class="white--text">
                + Add Platform
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Files -->
          <v-expansion-panel>
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Files</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-5">
              <v-card v-for="(doc, i) in formData.documents" :key="i" class="mb-5 pa-5">
                <v-row class="flex-wrap" no-gutters>
                  <v-col cols="6" no-gutters class="pr-2">
                    <v-text-field v-model="doc.name" label="Name" no-gutters hide-details disabled></v-text-field>
                  </v-col>
                  <v-col cols="6" no-gutters class="pl-2">
                    <v-select v-model="doc.idDocumentType" label="File Type" :items="documentTypes" item-value="idDocumentType" item-text="name" hide-details></v-select>
                  </v-col>
                  <v-col cols="12" no-gutters class="pt-2">
                    <v-file-input v-model="doc.file" label="Upload File" no-gutters></v-file-input>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="mt-5">
                  <v-btn @click="removeDocument(doc)" class="darken-1 red white--text">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-row>
              </v-card>
              <v-btn @click="addDocument()" color="primaryButton" class="white--text">
                + Add File
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Links -->
          <v-expansion-panel>
            <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
              <strong>Links</strong>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ma-5">
              <v-card v-for="(link, i) in formData.links" :key="i" class="mb-5 pa-5">
                <v-row class="flex-wrap" no-gutters>
                  <v-col cols="6" no-gutters class="pr-2">
                    <v-text-field v-model="link.name" label="Name" no-gutters hide-details></v-text-field>
                  </v-col>
                  <v-col cols="6" no-gutters class="pl-2">
                    <v-select v-model="link.idLinkType" label="Link Type" :items="linkTypes" item-value="idLinkType" item-text="name" hide-details></v-select>
                  </v-col>
                  <v-col cols="12" no-gutters class="pt-2">
                    <v-text-field v-model="link.url" label="URL" no-gutters :rules="requiredRules"></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="mt-5">
                  <v-btn @click="removeLink(link)" class="darken-1 red white--text">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-row>
              </v-card>
              <v-btn @click="addLink()" color="primaryButton" class="white--text">
                + Add Link
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css'

  export default {
    name: 'ProjectForm',

    props: ['inputData'],

    components: {
      DatePicker
    },

    data () {
      return {
        companies: [],
        documentTypes: [],
        error: '',
        filesPDF: [],
        filesSupporting: [],
        formData: {},
        heatmapTemplates: [],
        heatmapToLink: null,
        isShowingDateMenu: false,
        isShowingConfirmDeleteFileDialog: false,
        linkTypes: [],
        loading: false,
        logoFile: null,
        panel: [0, 1, 2, 3, 4, 5, 6],
        pendingDeleteFile: -1,
        pendingDeletePDF: -1,
        platforms: [],
        requiredRules: [
          v => !!v || 'Field is required.'
        ],
        valid: false
      }
    },

    created () {
      this.getCompanies()
      this.getDocumentTypes()
      this.getLinkTypes()
      this.getPlatforms()
      this.getHeatmapTemplates()
    },

    methods: {
      addDocument () {
        if (!this.formData.documents) this.formData.documents = []
        this.formData.documents.push({
          idDocument: 0,
          idDocumentType: 1
        })
        this.$forceUpdate()
      },

      addHeatmap () {
        if (!this.formData.heatmaps) this.formData.heatmaps = []
        if (this.formData.heatmaps.indexOf(this.heatmapToLink.id) == -1) this.formData.heatmaps.push(this.heatmapToLink.id)
        this.$forceUpdate()
      },

      addLink () {
        if (!this.formData.links) this.formData.links = []
        this.formData.links.push({
          idLink: 0,
          idLinkType: 1
        })
        this.$forceUpdate()
      },

      addPlatform () {
        if (!this.formData.platforms) this.formData.platforms = []
        this.formData.platforms.push({})
        this.$forceUpdate()
      },

      addPowerBiURL () {
        if (!this.formData.powerBiURLs) this.formData.powerBiURLs = []
        this.formData.powerBiURLs.push({})
        this.$forceUpdate()
      },

      computeCohortSize (value) {
        return Number(value)
      },

      getCompanies() {
        this.$store.dispatch('getCompanies')
        .then((resp) => {
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.companies = resp.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
            if (this.inputData.idProject) {
              this.formData = this.inputData
            }
          }
        })
        .catch((error) => {
          this.error = error
        })
      },

      getDocumentTypes () {
        this.$store.dispatch('getDocumentTypes')
        .then((resp) => {
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.documentTypes = resp
            this.$forceUpdate()
          }
        })
        .catch((error) => {
          this.error = error
        })
      },

      getHeatmapTemplates () {
        this.loading = true
        this.$store.dispatch('getHeatmapTemplates')
        .then((resp) => {
          this.loading = false
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.heatmapTemplates = resp
          }
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      getLinkTypes () {
        this.$store.dispatch('getLinkTypes')
        .then((resp) => {
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.linkTypes = resp
            this.$forceUpdate()
          }
        })
        .catch((error) => {
          this.error = error
        })
      },

      getPlatforms () {
        this.loading = true
        this.$store.dispatch('getPlatforms')
        .then((resp) => {
          this.loading = false
          if (resp.errors) {
            this.error = resp.errors
          } else {
            this.platforms = resp
          }
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
      },

      getStartDateTimeString () {
        var startDateTimeString = ""

        if (this.formData.startDateTime) {
          const start = this.formData.startDateTime
          const month = new Date(start).toLocaleString("en-US", { month: "long" })
          const date = new Date(start).getDate()
          const year = new Date(start).getFullYear()
          startDateTimeString = month + " " + date + ", " + year
        }

        return startDateTimeString
      },

      removeDocument (document) {
        if (this.formData && this.formData.documents) {
          const index = this.formData.documents.indexOf(document)
          if (index >= 0) {
            this.formData.documents.splice(index, 1)
            this.$forceUpdate()
          }
        }
      },

      removeHeatmap (index) {
        this.formData.heatmaps.splice(index, 1)
        this.$forceUpdate()
      },

      removeLink (link) {
        if (this.formData && this.formData.links) {
          const index = this.formData.links.indexOf(link)
          if (index >= 0) {
            this.formData.links.splice(index, 1)
            this.$forceUpdate()
          }
        }
      },

      removePlatform (index) {
        this.formData.platforms.splice(index, 1)
        this.$forceUpdate()
      },

      removeUploadedFile () {
        // TODO: delete the files upon submit
        if (this.pendingDeleteFile > -1) {
          this.formData.uploadedFiles.splice(this.pendingDeleteFile, 1)
          this.pendingDeleteFile = -1
          this.$forceUpdate()
        } else if (this.pendingDeletePDF > -1) {
          this.formData.summaryPDFs.splice(this.pendingDeletePDF, 1)
          this.pendingDeletePDF = -1
          this.$forceUpdate()
        }
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true

          // Modify dispatch params before sending
          const dispatchReq = this.formData.idProject ? "updateProject" : "createProject"

          // Startdatetime
          if (this.formData.startDateTime) this.formData.startDateTime = new Date(this.formData.startDateTime).toISOString()

          // Append params to embed URLs
          if (this.formData.links) {
            for (var i = 0; i < this.formData.links.length; i++) {
              if (this.formData.links[i].idLinkType == "3" && this.formData.links[i].url.indexOf("&action") == -1) {
                this.formData.links[i].url += "&action=embedview&wdAllowInteractivity=False&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True"
              }
            }
          }

          var dispatchParams = this.formData

          // Logo and files
          if (this.logoFile) dispatchParams.logo = this.logoFile
          else delete dispatchParams.logo

          // Publishers and developers
          if (dispatchParams.publishers && dispatchParams.publishers.length > 0) {
            for (i = 0; i < dispatchParams.publishers.length; i++) {
              if (!dispatchParams.publishers[i].idCompany) {
                dispatchParams.publishers[i] = {
                  idCompany: dispatchParams.publishers[i]
                }
              }
            }
          }
          if (dispatchParams.developers && dispatchParams.developers.length > 0) {
            for (i = 0; i < dispatchParams.developers.length; i++) {
              if (!dispatchParams.developers[i].idCompany) {
                dispatchParams.developers[i] = {
                  idCompany: dispatchParams.developers[i]
                }
              }
            }
          }

          // Change document type from string to integer
          /* if (dispatchParams.documents && dispatchParams.documents.length > 0) {
            for (i = 0; i < dispatchParams.documents.length; i++) {
              dispatchParams.documents[i].idDocumentType = this.documentTypes.find(type => type.name == dispatchParams.documents[i].name).idDocument
            }
          }
          if (dispatchParams.links && dispatchParams.links.length > 0) {
            for (i = 0; i < dispatchParams.links.length; i++) {
              dispatchParams.links[i].idLinkType = this.linkTypes.find(type => type.name == dispatchParams.links[i].name).idLink
            }
          } */

          this.$store.dispatch(dispatchReq, dispatchParams)
          .then((projectData) => {
            this.$emit('onSubmitComplete', projectData)
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        }
      }
    }
  }
</script>

<style>
  .date-select input {
    max-width: 325px;
  }

  .mx-icon-calendar {
    color: white;
    padding-bottom: 10px;
  }

  .mx-icon-clear {
    color: white;
    padding-bottom: 10px;
  }

    .mx-icon-clear:hover {
      color: white;
      padding-bottom: 10px;
    }

  .v-expansion-panel-content__wrap {
    margin: 0;
    padding: 0;
  }
</style>