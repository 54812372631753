<template>
  <v-card>
    <h3 class="pa-3 text-center white--text" style="background-color: #2B3C4F;">{{ projectData.name }}</h3>
    <v-row class="pa-5" align="center">
      <v-col cols="2" class="flex-grow-1 flex-shrink-0">
        <v-img id="logo" :src="projectLogo" contain max-width="128" max-height="128" class="grey lighten-3" style="background-color: #00000000 !important;"></v-img>
        <!-- <img id="logo" contain max-width="128" max-height="128" class="grey lighten-3" style="background-color: #00000000 !important;"/> -->
        <!-- <v-img v-else src="@/assets/logo-dark.png" contain max-width="128" max-height="128" class="grey lighten-3" style="background-color: #00000000 !important;"></v-img> -->
      </v-col>
      <v-col cols="5" class="flex-grow-0 flex-shrink-1 pl-5">
        <div><strong>Publisher{{ pub2 ? 's' : '' }}:</strong> {{ (pub || '') + (pub2 ? (', ' + pub2) : '') }}</div>
        <div><strong>Platforms:</strong> <span v-for="(platform, i) in projectData.platforms" :key="i">{{ platform.name + (i === projectData.platforms.length - 1 ? "" : ", ") }}</span></div>
        <div><strong>Cohort Size:</strong> {{ projectData.cohortSizeTotal == undefined ? calculateCohortSize() : projectData.cohortSizeTotal }}</div>
      </v-col>
      <v-col cols="5" class="flex-grow-0 flex-shrink-1 pl-5">
        <div><strong>Developer{{ dev2 ? 's' : '' }}:</strong> {{ (dev || '') + (dev2 ? (', ' + dev2) : '') }}</div>
        <div><strong>Date/Time:</strong> {{ getStartDateTimeString() }}</div>
        <div><strong>Duration:</strong> {{ projectData.duration }} hours</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'ProjectThumbnail',

    props: ['projectData'],

    data () {
      return {
        projectLogo: null
      }
    },

    computed: {
      dev () {
        var value = null

        if (this.projectData.developers && this.projectData.developers.length > 0) {
          if (this.projectData.developers[0].company) {
            value = this.projectData.developers[0].company.name
          } else {
            value = this.projectData.developers[0].name
          }
        }

        return value
      },
      dev2 () {
        var value = null

        if (this.projectData.developers && this.projectData.developers.length > 1) {
          if (this.projectData.developers[1].company) {
            value = this.projectData.developers[1].company.name
          } else {
            value = this.projectData.developers[1].name
          }
        }

        return value
      },
      pub () {
        var value = null

        if (this.projectData.publishers && this.projectData.publishers.length > 0) {
          if (this.projectData.publishers[0].company) {
            value = this.projectData.publishers[0].company.name
          } else {
            value = this.projectData.publishers[0].name
          }
        }

        return value
      },
      pub2 () {
        var value = null

        if (this.projectData.publishers && this.projectData.publishers.length > 1) {
          if (this.projectData.publishers[1].company) {
            value = this.projectData.publishers[1].company.name
          } else {
            value = this.projectData.publishers[1].name
          }
        }

        return value
      }
    },

    created () {
      // Retrieve logo
      if (!this.projectData.logo) return
      this.loading = true
      this.$store.dispatch('getLogo', this.projectData.logo)
      .then((resp) => {
        this.projectLogo = resp
        this.loading = false
      })
      .catch((error) => {
        this.error = error
        this.loading = false
        if (this.error.code == 'permission-denied') {
          this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
        }
      })
    },

    methods: {
      calculateCohortSize () {
        if (this.projectData.platforms) {
          var cohortSize = 0
          this.projectData.platforms.forEach(platform => cohortSize += platform.cohortSize)
          return cohortSize
        } else {
          return 0
        }
      },

      getStartDateTimeString () {
        var startDateTimeString = ""

        if (this.projectData.startDateTime) {
          const start = this.projectData.startDateTime
          const month = new Date(start).toLocaleString("en-US", { month: "long" })
          const date = new Date(start).getDate()
          const year = new Date(start).getFullYear()
          startDateTimeString = month + " " + date + ", " + year
        }

        return startDateTimeString
      }
    }
  }
</script>
